#sidebar {
  width: 230px;
  margin-left: 20px;

  font-size: 14px;
  position: fixed;
  height: 100%;
  right: 0px;
  overflow-y: scroll;
  /* background-color: #ecf0f1; */
}

#sidebar #sidebar-content {
  margin-top: 10px;
  margin-right: 16px;
}

#sidebar .block {
  padding: 20px;
  margin-top: 20px;
}

#sidebar .hard-sentence {
  background-color: rgba(241, 196, 15, 0.8);
}
#sidebar .very-hard-sentence {
  background-color: rgba(192, 57, 43, 0.8);
}
#sidebar .adverb {
  background-color: rgba(43, 125, 192, 0.8);
}
