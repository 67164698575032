#main .editor-wrapper {
  display: flex;
  padding-right: 230px;
  padding-top: 40px;

  justify-content: center;
}

#main .editor-content {
  width: 600px;
  font-size: 20px;
  line-height: 26px;
}

#main .sentence-normal {
  transition: background-color 0.5s ease;
}
#main .sentence-hard {
  transition: background-color 0.5s ease;
  background-color: rgba(241, 196, 15, 0.8);
}
#main .sentence-veryhard {
  transition: background-color 0.5s ease;
  background-color: rgba(192, 57, 43, 0.8);
}
#main .adverb-word {
  transition: background-color 0.5s ease;
  background-color: rgba(43, 125, 192, 0.8);
}
